import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GetTeamMembersQuery } from "@/utils/graphql/types";
import { TeamMembersCard } from "./TeamMembersCard";
import { GET_TEAM_MEMBERS } from "./TeamMembersCard.graphql";
import { TeamMember } from "./types";

export function TeamMembersCardLoader() {
  const { data } = useQuery<GetTeamMembersQuery>(GET_TEAM_MEMBERS);

  const teamMembers: TeamMember[] = useMemo(() => {
    const teamMembersData: TeamMember[] = [];
    if (!data) return teamMembersData;

    teamMembersData.push(
      ...data.devCenterUsers.nodes.map(node => ({
        id: node.id,
        email: node.email,
        name: node.name,
        isAccountOwner: node.isAccountOwner,
        status: "ACTIVE" as const,
      })),
      ...data.devCenterUserInvites.nodes.map(node => ({
        id: node.id,
        email: node.email,
        name: node.name,
        status: node.status,
        isAccountOwner: false,
      })),
    );
    return teamMembersData;
  }, [data]);

  return <TeamMembersCard teamMembers={teamMembers} />;
}
