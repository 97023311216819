import React from "react";
import { Page } from "@/components/Page";
import { withAuthentication } from "@/features/Authentication/AccessRestriction";
import { SettingsDashboard } from "@/features/Personalization/SettingsDashboard/";

function SettingsPage() {
  return (
    <Page>
      <SettingsDashboard />
    </Page>
  );
}

export default withAuthentication(SettingsPage);
