import React from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { showToast } from "@jobber/components/Toast";
import { FetchResult } from "@apollo/client";
// eslint-disable-next-line no-restricted-imports, import/no-relative-parent-imports
import { DevCenterUserInviteCancelMutation } from "@/utils/graphql/types";
import { TeamMember } from "../types";

interface TeamMemberCancelInviteModalProps {
  onCancelInvite(
    teamMember: TeamMember,
  ): Promise<FetchResult<DevCenterUserInviteCancelMutation>>;
  onRequestClose: () => void;
  open: boolean;
  teamMember: TeamMember;
}

export function TeamMemberCancelInviteModal({
  onCancelInvite,
  onRequestClose,
  open,
  teamMember,
}: TeamMemberCancelInviteModalProps) {
  return (
    <Modal
      open={open}
      title={`Cancel team member invite`}
      size="small"
      primaryAction={{
        variation: "destructive",
        label: "Cancel Invite",
        onClick: async () => {
          const { data } = await onCancelInvite(teamMember);
          const userErrors = data?.devCenterUserInviteCancel?.userErrors;

          if (userErrors && userErrors.length > 0) {
            showToast({
              variation: "error",
              message: `Something went wrong. Please try again.`,
            });
            return;
          }

          showToast({
            message: `Team member invite has been successfully cancelled.`,
          });

          onRequestClose();
        },
      }}
      secondaryAction={{
        variation: "subtle",
        label: "Go Back",
        onClick: onRequestClose,
      }}
      onRequestClose={onRequestClose}
    >
      <Content>
        <Text>
          Cancelling the invite will revoke this team member&apos;s ability to
          join your account.
        </Text>
        <Text>This action cannot be undone.</Text>
      </Content>
    </Modal>
  );
}
