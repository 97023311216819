import React from "react";
import { navigate } from "gatsby";
import { Card } from "@jobber/components/Card";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import * as styles from "./TwoFactorAuthenticationCard.module.css";

interface TwoFactorAuthenticationCardProps {
  MFAEnabled?: boolean;
}
export function TwoFactorAuthenticationCard({
  MFAEnabled,
}: TwoFactorAuthenticationCardProps) {
  return (
    <Card title="Two-Factor Authentication">
      <Content>
        <Text>
          Two-factor authentication increases the security of your account by
          requiring more than just a password to log in.
        </Text>
        <div className={styles.mfaApp}>
          <Heading level={3}>Authenticator app</Heading>
          <Button
            onClick={handleAuthenticatorEdit}
            type="tertiary"
            {...(MFAEnabled == undefined
              ? {
                  label: "Add",
                  loading: true,
                }
              : { label: MFAEnabled ? "Edit" : "Add" })}
          />
        </div>
      </Content>
    </Card>
  );

  function handleAuthenticatorEdit() {
    navigate("/settings/mfa_setup");
  }
}
