import { gql } from "@apollo/client";

export const CREATE_DEV_CENTER_USER_INVITE = gql`
  mutation CreateDevCenterUserInvite(
    $input: DevCenterUserInviteCreateAttributes!
  ) {
    devCenterUserInviteCreate(input: $input) {
      userErrors {
        message
        path
      }
    }
  }
`;
