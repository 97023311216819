import React, { ComponentProps } from "react";
import { useMutation } from "@apollo/client";
import {
  DevCenterUserInviteCancelMutation,
  DevCenterUserInviteCancelMutationVariables,
} from "@/utils/graphql/types";
import { TeamMemberCancelInviteModal } from "./TeamMemberCancelInviteModal";
import { DEV_CENTER_USER_INVITE_CANCEL } from "./TeamMemberCancelInviteModal.graphql";

type TeamMemberCancelInviteModalLoaderProps = Pick<
  ComponentProps<typeof TeamMemberCancelInviteModal>,
  "open" | "onRequestClose" | "teamMember"
>;

export function TeamMemberCancelInviteModalLoader(
  props: TeamMemberCancelInviteModalLoaderProps,
) {
  const [cancelDevCenterUserInvite] = useMutation<
    DevCenterUserInviteCancelMutation,
    DevCenterUserInviteCancelMutationVariables
  >(DEV_CENTER_USER_INVITE_CANCEL);

  return (
    <TeamMemberCancelInviteModal
      {...props}
      onCancelInvite={devCenterUserInvite =>
        cancelDevCenterUserInvite({
          variables: { devCenterUserInviteId: devCenterUserInvite.id },
          refetchQueries: ["GetTeamMembers"],
        })
      }
    />
  );
}
