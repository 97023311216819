import React from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { InputText } from "@jobber/components/InputText";

interface AccountDetailsCardProps {
  name?: string;
  email?: string;
}

export function AccountDetailsCard({
  email = "",
  name = "",
}: AccountDetailsCardProps) {
  return (
    <Card header={{ title: "My details" }}>
      <Content>
        {name && <InputText placeholder="Name" value={name} readonly />}
        <InputText placeholder="Email" value={email} readonly />
      </Content>
    </Card>
  );
}
