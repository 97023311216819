import React from "react";
import { Button } from "@jobber/components/Button";
import { Menu } from "@jobber/components/Menu";
import { TeamMember } from "./types";

export function TeamMemberMenu({
  onCancelInvite,
  onRemoveTeamMember,
  teamMember,
}: {
  onCancelInvite: () => void;
  onRemoveTeamMember: () => void;
  teamMember: TeamMember;
}) {
  const { status } = teamMember;
  const isActive = status === "ACTIVE";
  return (
    <Menu
      activator={
        <Button
          variation="subtle"
          type="tertiary"
          icon="more"
          ariaLabel="more"
        />
      }
      items={[
        {
          actions: [
            {
              label: isActive ? "Remove team member" : "Cancel invite",
              icon: "trash",
              onClick: isActive ? onRemoveTeamMember : onCancelInvite,
            },
          ],
        },
      ]}
    />
  );
}
