import { gql } from "@apollo/client";

export const REMOVE_DEV_CENTER_USER = gql`
  mutation RemoveDevCenterUser($devCenterUserId: EncodedId!) {
    devCenterUserRemove(devCenterUserId: $devCenterUserId) {
      userErrors {
        message
      }
    }
  }
`;
