import React from "react";
import { Content } from "@jobber/components/Content";
import { Page } from "@jobber/components/Page";
// eslint-disable-next-line import/no-relative-parent-imports
import { Banner } from "@/components/Banner";
import { AccountDetailsCard } from "./AccountDetailsCard";
import { TwoFactorAuthenticationCard } from "./TwoFactorAuthenticationCard";
import * as styles from "./SettingsDashboard.module.css";
import { TeamMembersCard } from "./TeamMembersCard";

export function SettingsDashboard() {
  return (
    <div className={styles.settingsWrapper}>
      <Banner className={styles.banner} />
      <Page title="Settings">
        <Content>
          <AccountDetailsCard />
          <TwoFactorAuthenticationCard />
          <TeamMembersCard />
        </Content>
      </Page>
    </div>
  );
}
