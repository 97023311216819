import React from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { TeamMember } from "./types";

export function TeamMemberStatus({ teamMember }: { teamMember: TeamMember }) {
  const { status } = teamMember;
  return (
    <InlineLabel color={status === "ACTIVE" ? "teal" : "yellow"}>
      {status === "ACTIVE" ? "Active" : "Invitation pending"}
    </InlineLabel>
  );
}
