import { gql } from "@apollo/client";

export const GET_TEAM_MEMBERS = gql`
  query GetTeamMembers {
    devCenterUsers {
      nodes {
        id
        email
        name
        isAccountOwner
      }
    }
    devCenterUserInvites {
      nodes {
        id
        email
        name
        status
      }
    }
  }
`;
