import React, { ComponentProps } from "react";
import { useMutation } from "@apollo/client";
import {
  CreateDevCenterUserInviteMutation,
  CreateDevCenterUserInviteMutationVariables,
} from "@/utils/graphql/types";
import { TeamMemberInviteModal } from "./TeamMemberInviteModal";
import { CREATE_DEV_CENTER_USER_INVITE } from "./TeamMemberInviteModal.graphql";

type TeamMemberInviteModalLoaderProps = Pick<
  ComponentProps<typeof TeamMemberInviteModal>,
  "open" | "onRequestClose"
>;

export function TeamMemberInviteModalLoader({
  onRequestClose,
  open,
}: TeamMemberInviteModalLoaderProps) {
  const [inviteTeamMember] = useMutation<
    CreateDevCenterUserInviteMutation,
    CreateDevCenterUserInviteMutationVariables
  >(CREATE_DEV_CENTER_USER_INVITE);

  return (
    <TeamMemberInviteModal
      open={open}
      onRequestClose={onRequestClose}
      onSendInvite={({ name, email }) =>
        inviteTeamMember({
          variables: { input: { name, email } },
          refetchQueries: ["GetTeamMembers"],
        })
      }
    />
  );
}
