import React, { ComponentProps } from "react";
import { useMutation } from "@apollo/client";
import {
  RemoveDevCenterUserMutation,
  RemoveDevCenterUserMutationVariables,
} from "@/utils/graphql/types";
import { TeamMemberRemoveModal } from "./TeamMemberRemoveModal";
import { REMOVE_DEV_CENTER_USER } from "./TeamMemberRemoveModal.graphql";

type TeamMemberRemoveModalLoaderProps = Pick<
  ComponentProps<typeof TeamMemberRemoveModal>,
  "open" | "onRequestClose" | "teamMember"
>;

export function TeamMemberRemoveModalLoader(
  props: TeamMemberRemoveModalLoaderProps,
) {
  const [removeDevCenterUserMember] = useMutation<
    RemoveDevCenterUserMutation,
    RemoveDevCenterUserMutationVariables
  >(REMOVE_DEV_CENTER_USER);
  return (
    <TeamMemberRemoveModal
      {...props}
      onRemoveTeamMember={devCenterUser =>
        removeDevCenterUserMember({
          variables: { devCenterUserId: devCenterUser.id },
          refetchQueries: ["GetTeamMembers"],
        })
      }
    />
  );
}
