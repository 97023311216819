import { gql } from "@apollo/client";

export const DEV_CENTER_USER_INVITE_CANCEL = gql`
  mutation DevCenterUserInviteCancel($devCenterUserInviteId: EncodedId!) {
    devCenterUserInviteCancel(devCenterUserInviteId: $devCenterUserInviteId) {
      userErrors {
        message
        path
      }
    }
  }
`;
