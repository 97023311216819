import React from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { TeamMember } from "./types";

export function TeamMemberInfo({ teamMember }: { teamMember: TeamMember }) {
  const { email, isAccountOwner, name } = teamMember;
  return (
    <Content spacing="small">
      <Text>
        <Emphasis variation="bold">{name ? `${name} ` : ""}</Emphasis>
        {isAccountOwner ? "(account owner)" : ""}
      </Text>
      <Text>{email}</Text>
    </Content>
  );
}
