import React from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
// eslint-disable-next-line no-restricted-imports, import/no-relative-parent-imports
import { FetchResult } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import { RemoveDevCenterUserMutation } from "@/utils/graphql/types";
import { TeamMember } from "../types";

export function TeamMemberRemoveModal({
  onRemoveTeamMember,
  onRequestClose,
  open,
  teamMember,
}: {
  onRemoveTeamMember(
    teamMember: TeamMember,
  ): Promise<FetchResult<RemoveDevCenterUserMutation>>;
  onRequestClose(): void;
  open: boolean;
  teamMember: TeamMember;
}) {
  return (
    <Modal
      open={open}
      title={`Remove ${teamMember.name} from account`}
      size="small"
      primaryAction={{
        variation: "destructive",
        label: "Remove Team Member",
        onClick: async () => {
          const { data } = await onRemoveTeamMember(teamMember);
          if (
            data?.devCenterUserRemove.userErrors &&
            data?.devCenterUserRemove.userErrors.length > 0
          ) {
            showToast({
              variation: "error",
              message: `Something went wrong. Please try again.`,
            });
            return;
          }
          showToast({
            message: `${
              teamMember.name ? teamMember.name : "Team member"
            } has been successfully removed from this account`,
          });
          onRequestClose();
        },
      }}
      secondaryAction={{
        variation: "subtle",
        label: "Cancel",
        onClick: onRequestClose,
      }}
      onRequestClose={onRequestClose}
    >
      <Content>
        <Text>
          Removing team member will permanently delete them from this account
          and remove their access to all apps in this account.
        </Text>
        <Text>This action cannot be undone.</Text>
      </Content>
    </Modal>
  );
}
