import React, { Fragment, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Text } from "@jobber/components/Text";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Divider } from "@jobber/components/Divider";
import { navigate } from "gatsby";
import { useUserContext } from "@/context/user";
import { useBannerContext } from "@/context/banner";
import * as styles from "./TeamMembersCard.module.css";
import { TeamMember } from "./types";
import { TeamMemberStatus } from "./TeamMemberStatus";
import { TeamMemberMenu } from "./TeamMemberMenu";
import { TeamMemberInfo } from "./TeamMemberInfo";
import { TeamMemberRemoveModal } from "./TeamMemberRemoveModal";
import { TeamMemberCancelInviteModal } from "./TeamMemberCancelInviteModal";
import { TeamMemberInviteModal } from "./TeamMemberInviteModal";

export function TeamMembersCard({
  teamMembers,
}: {
  teamMembers: TeamMember[];
}) {
  const [selectedTeamMember, setSelectedTeamMember] = useState<TeamMember>();
  const [inviteTeamMemberModalOpen, setInviteTeamMemberModalOpen] =
    useState(false);
  const { MFAEnabled } = useUserContext();
  const { showBanner } = useBannerContext();

  const handleInviteTeamMember = () => {
    if (!MFAEnabled) {
      showBanner({
        message:
          "Two-factor authentication is required in order to invite team members. Add an authentication app to secure your account.",
        variation: "error",
        primaryAction: {
          label: "Add 2FA",
          onClick: () => navigate("/settings/mfa_setup"),
        },
      });
      return;
    }
    setInviteTeamMemberModalOpen(true);
  };

  return (
    <>
      <Card
        header={{
          title: "Team Members",
          action: (
            <Button
              icon="add"
              label="Invite Team Member"
              onClick={handleInviteTeamMember}
            />
          ),
        }}
      >
        <TeamMembersList
          teamMembers={teamMembers}
          onCancelInvite={teamMember => setSelectedTeamMember(teamMember)}
          onRemoveTeamMember={teamMember => setSelectedTeamMember(teamMember)}
        />
      </Card>
      {inviteTeamMemberModalOpen && (
        <TeamMemberInviteModal
          open={inviteTeamMemberModalOpen}
          onRequestClose={() => setInviteTeamMemberModalOpen(false)}
        />
      )}
      {selectedTeamMember &&
        (selectedTeamMember.status == "ACTIVE" ? (
          <TeamMemberRemoveModal
            open={!!selectedTeamMember}
            onRequestClose={() => setSelectedTeamMember(undefined)}
            teamMember={selectedTeamMember}
          />
        ) : (
          <TeamMemberCancelInviteModal
            open={!!selectedTeamMember}
            onRequestClose={() => setSelectedTeamMember(undefined)}
            teamMember={selectedTeamMember}
          />
        ))}
    </>
  );
}

function TeamMembersList({
  teamMembers,
  onCancelInvite,
  onRemoveTeamMember,
}: {
  teamMembers: TeamMember[];
  onCancelInvite: (teamMember: TeamMember) => void;
  onRemoveTeamMember: (teamMember: TeamMember) => void;
}) {
  const { email: currentUserEmail } = useUserContext();

  if (teamMembers.length === 0) {
    return (
      <Content>
        <Text>
          No team members yet. You can invite team members to join and
          collaborate on your account.
        </Text>
      </Content>
    );
  }

  return (
    <div className={styles.cardContainer} data-testid="team-members-card">
      {teamMembers.map((teamMember, index) => {
        const { email, isAccountOwner } = teamMember;
        const isLastTeamMember = index === teamMembers.length - 1;
        const showAction = email !== currentUserEmail;
        return (
          <Fragment key={email}>
            <Content>
              <div className={styles.container} data-testid={email}>
                <div className={styles.teamMember}>
                  <TeamMemberInfo teamMember={teamMember} />
                </div>
                <div className={styles.status}>
                  <TeamMemberStatus teamMember={teamMember} />
                </div>
                <div className={styles.action}>
                  {!isAccountOwner && showAction && (
                    <TeamMemberMenu
                      teamMember={teamMember}
                      onCancelInvite={() => onCancelInvite(teamMember)}
                      onRemoveTeamMember={() => onRemoveTeamMember(teamMember)}
                    />
                  )}
                </div>
              </div>
            </Content>
            {!isLastTeamMember && <Divider />}
          </Fragment>
        );
      })}
    </div>
  );
}
