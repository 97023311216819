import React, { useState } from "react";
import { Content } from "@jobber/components/Content";
import { InputText } from "@jobber/components/InputText";
import { Button } from "@jobber/components/Button";
import { InputEmail } from "@jobber/components/InputEmail";
import { Form } from "@jobber/components/Form";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { showToast } from "@jobber/components/Toast";
import { InputValidation } from "@jobber/components/InputValidation";
import { FetchResult } from "@apollo/client";
// eslint-disable-next-line import/no-relative-parent-imports
import { CreateDevCenterUserInviteMutation } from "@/utils/graphql/types";
import * as styles from "./TeamMemberInviteModal.module.css";

interface TeamMemberInviteModalProps {
  onRequestClose: () => void;
  onSendInvite({
    name,
    email,
  }: {
    name: string;
    email: string;
  }): Promise<FetchResult<CreateDevCenterUserInviteMutation>>;
  open: boolean;
}

export function TeamMemberInviteModal({
  onRequestClose,
  onSendInvite,
  open,
}: TeamMemberInviteModalProps) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hasUserErrors, setHasUserErrors] = useState(false);

  return (
    <Modal
      title="Invite team member"
      open={open}
      onRequestClose={onRequestClose}
    >
      <Content>
        <Form
          onSubmit={async () => {
            const { data } = await onSendInvite({
              name: name.trim(),
              email: email.trim().toLowerCase(),
            });

            if (
              data?.devCenterUserInviteCreate?.userErrors &&
              data?.devCenterUserInviteCreate?.userErrors.length > 0
            ) {
              setHasUserErrors(true);
              return;
            }

            showToast({
              message: `Team member invited`,
            });
            onRequestClose();
          }}
        >
          <Content>
            <Text>
              Enter team member details below and send an invite to join this
              account.
            </Text>
            <div className={styles.smaller}>
              <InputText
                placeholder="Name"
                name="name"
                value={name}
                onChange={(value: string) => setName(value)}
                validations={{
                  required: {
                    value: true,
                    message: "Please provide a name",
                  },
                }}
              />
            </div>
            <div className={styles.smaller}>
              <InputEmail
                placeholder="Email"
                name="email"
                value={email}
                onChange={(value: string) => {
                  setEmail(value);
                  if (hasUserErrors) setHasUserErrors(false);
                }}
                validations={{
                  required: {
                    value: true,
                    message: "Please provide an email address",
                  },
                }}
              />
              {hasUserErrors && (
                <InputValidation
                  message={`Email is already in use in Jobber's Developer Center`}
                />
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                label="Cancel"
                variation="subtle"
                onClick={onRequestClose}
              />
              <Button label="Send Invite" submit={true} />
            </div>
          </Content>
        </Form>
      </Content>
    </Modal>
  );
}
